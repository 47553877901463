/* eslint-disable camelcase */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, {useEffect, useRef, useState, useContext} from 'react';
import TableRow from '@mui/material/TableRow';
import {isEqual, omit} from 'lodash';

import ScannerContext from '../../Context/ScannerContext';
import MemoizedScannerCell from './ScannerCell';


const isAddedToWatchlist = ({activeWatchlistSymbols, allowedWatchlist, value}) => {
  if (!allowedWatchlist || !activeWatchlistSymbols?.length || !Array.isArray(activeWatchlistSymbols)) return false;
  return activeWatchlistSymbols?.includes(value);
};


const ScannerRow = ({row, activeWatchlistSymbols, groupName, orderByState, orderState}) => {
  const {group, optionsColumns, optionsAllowed, lockedColumns} = useContext(ScannerContext);
  const cellRef = useRef(null);
  const [highlightKeys, setHighlightKeys] = useState(Object.keys(row).filter((k) => k.includes('Prev')));
  const [filteredDataTypes, setFilteredDataTypes] = useState([]);
  const [addedToWatchlist, setIsAddedToWatchlist] = useState(false);

  useEffect(() => {
    if (!highlightKeys?.length) {
      const prevHighlightKeys = Object.keys(row).filter((k) => k.includes('Prev'));
      if (!isEqual(prevHighlightKeys, highlightKeys)) {
        setHighlightKeys(prevHighlightKeys);
      }
    }
  }, [row]);

  const highlightRow = (className, prevRowKey, newRowKey) => {
    if (!row[prevRowKey] || !row[newRowKey]) return;
    const prevValue = Number(row[prevRowKey]?.value);
    const newRowVal = Number(row[newRowKey]?.value);
    const animationClassNameIncrease = `${className}Increase`;
    const animationClassNameDecrease = `${className}Decrease`;
    const animationAnimationOutClassName = `${className}AnimationOut`;
    cellRef.current.classList.remove(animationAnimationOutClassName);
    if (prevValue < newRowVal) {
      cellRef.current.classList.add(animationClassNameIncrease);
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove(animationClassNameIncrease);
          cellRef.current.classList.remove(animationClassNameDecrease);
          if (
            cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`)?.classList?.length &&
            !cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`).classList.contains('backend-text-color')
          ) {
            cellRef.current.classList.add(animationAnimationOutClassName);
          }
        }
      }, 1000);
    } else if (prevValue > newRowVal) {
      cellRef.current.classList.add(animationClassNameDecrease);
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove(animationClassNameIncrease);
          cellRef.current.classList.remove(animationClassNameDecrease);
          if (
            cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`)?.classList?.length &&
            !cellRef.current.querySelector(`.${newRowKey} .${newRowKey}`).classList.contains('backend-text-color')
          ) {
            cellRef.current.classList.add(animationAnimationOutClassName);
          }
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (cellRef.current && row.update) {
      if (!highlightKeys?.length) {
        return;
      }
      const rowKeys = highlightKeys.map((k) => {
        const newKey = k.replace('Prev', '');
        return {
          key: newKey,
          prevKey: k,
        };
      });
      rowKeys.forEach((k) => {
        if (row[k.key] && row[k.prevKey] && row[k.key]?.value !== row[k.prevKey]?.value) {
          highlightRow(k.key, k.prevKey, k.key);
        }
      });
    }
  }, [row]);

  useEffect(() => {
    if (!group?.dataTypes?.length) return;
    setFilteredDataTypes(group?.dataTypes.filter((dt) => !dt?.hideColumn));
  }, [group?.dataTypes]);

  useEffect(() => {
    const isAdded = isAddedToWatchlist({
      activeWatchlistSymbols,
      allowedWatchlist: group?.allowedWatchlist,
      value: row?.Symbol?.value,
    });
    if (addedToWatchlist !== isAdded) {
      setIsAddedToWatchlist(isAdded);
    }
  }, [activeWatchlistSymbols, row]);

  return (
    <TableRow hover tabIndex={-1} ref={cellRef}>
      {filteredDataTypes?.length
        ? filteredDataTypes.map((dataType, index) => (
            <MemoizedScannerCell
              key={dataType.key}
              rowValue={row}
              dataType={dataType}
              dataTypes={group?.dataTypes}
              optionsColumns={optionsColumns}
              row={row}
              index={index}
              optionsAllowed={optionsAllowed}
              lockedColumns={lockedColumns}
              allowedChart={group?.allowedChart}
              allowedWatchlist={group?.allowedWatchlist}
              groupName={groupName}
              group={group}
              addedToWatchlist={dataType?.key === 'Symbol' ? addedToWatchlist : false}
              order={orderState}
              orderBy={orderByState}
            />
          ))
        : null}
    </TableRow>
  );
};

export default React.memo(ScannerRow, (prev, next) => {
  const rowValueSame = isEqual(omit(prev?.row, ['_id']), omit(next?.row, ['_id']));
  const watchlistSame = isEqual(prev?.activeWatchlistSymbols, next?.activeWatchlistSymbols);
  const orderStateSame = prev?.orderState === next?.orderState;
  const orderByStateSame = prev?.orderByState === next?.orderByState;
  // const isSame = (rowValueSame && watchlistSame && orderStateSame && orderByStateSame);
  // if (!isSame) {
    // console.log('ScannerRow changed');
    // console.log(rowValueSame, watchlistSame, orderStateSame, orderByStateSame);
    // if (!rowValueSame) {
    //   console.log(prev?.row, next?.row);
    // }
  // }
  return rowValueSame && watchlistSame && orderStateSame && orderByStateSame;
});
